/** @jsx jsx */
import { Fragment, useCallback } from "react"
import { jsx, Flex, Box, Badge, Heading } from "theme-ui"

import Logo from "../components/Logo"
import AppLinks from "../components/AppDownloadLinks"
import Layout from "../layout"

const badges = [
  "Meet a mentor",
  "Proof of Abilities",
  "Unbiased Job invites",
  "Hire a coach",
  "Quests",
  "Guided learning",
  "$$",
]

const IndexPage = () => {
  const renderBadges = useCallback(
    () =>
      badges.map(badge => (
        <Badge mx={1} my={1} key={badge}>
          {badge}
        </Badge>
      )),
    []
  )

  return (
    <Fragment>
      <Layout title="Career Wallet made for humans by humans">
        <Flex
          py={5}
          sx={{
            textAlign: "center",
            flexDirection: "column",
            flex: "1 1 0%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo size={24} />
          <Box mt={4} mb={4} sx={{ maxWidth: [450] }}>
            <Heading as="h2" sx={{ fontWeight: 500, color: "#3c3b3b" }}>
              The place to hangout and meet people when developing your
              professional life
            </Heading>
          </Box>
          <Flex
            sx={{
              maxWidth: [450],
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {renderBadges()}
          </Flex>
          {/* <Box mt={4} sx={{ maxWidth: [450] }}>
            <Paragraph>
              Hey, we're still opening up but anyone can join with an invite
              from an existing user!{" "}
            </Paragraph>
          </Box> */}
          <AppLinks mt={4} />
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default IndexPage
