/** @jsx jsx */
import { useCallback, useMemo } from "react"
import { jsx, Flex, Link, Image, Label } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import appStoreImg from "../../images/appstore.svg"
import playStoreImg from "../../images/googleplay.svg"

const imgMap = {
  appstore: appStoreImg,
  playstore: playStoreImg,
}

function AppLinks(props) {
  const {
    data: { links },
  } = useStaticQuery(query)

  const activeLinks = useMemo(() => links.filter(item => item.hide !== true), [
    links,
  ])

  const renderLinks = useCallback(
    () =>
      activeLinks.map((item, i) => (
        <Link
          key={item.id}
          href={item.uri}
          mr={`${i - links.length !== -1 ? 2 : 0}`}
          disabled={item.inActive}
          sx={{
            pointerEvents: () => (item.inActive ? "none" : "auto"),
            cursor: "pointer",
          }}
        >
          <Image
            sx={{
              opacity: () => (item.inActive ? 0.42 : 1),
            }}
            src={imgMap[item.id]}
            alt={item.alt}
          />
          {item.inActive && (
            <Label
              sx={{
                justifyContent: "center",
                alignItems: "center",
                fontSize: 1,
                color: "#949494",
              }}
            >
              Awaiting release
            </Label>
          )}
        </Link>
      )),
    [activeLinks]
  )
  if (activeLinks.length < 1) return null
  return <Flex {...props}>{renderLinks()}</Flex>
}

export default AppLinks

const query = graphql`
  query AppDownloadLinksQuery {
    data: settingsJson {
      links: appDownloadLinks {
        id
        alt
        uri
        hide
        inActive
      }
    }
  }
`
